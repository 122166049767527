import React from "react"
import Layout from "../components/Layout"

export default function Kanzlei() {
  return (
    <Layout>
      <div className="deco">
          <img
            src="/kanzlei.jpg"
            alt="T&auml;tigkeitsbereiche"
          />        
      </div>
      <div className="content">
        <h1>Ihre Kanzlei in Oberbayern</h1>
        <p className="no-margin">
          Sie finden die Kanzlei Schweikert auf halbem Weg zwischen dem
          Landgericht Traunstein und dem Oberlandesgericht M&uuml;nchen. Aber
          auch die Amtsgerichte Traunstein, M&uuml;nchen, Laufen,
          M&uuml;hldorf, Alt&ouml;tting, Garmisch- Partenkirchen,
          Wolfratshausen, Weilheim, Miesbach, Dachau, F&uuml;rstenfeldbruck,
          Ebersberg, Starnberg und Rosenheim mit den Zweigstellen Bad Aibling
          und Wasserburg sind kurzfristig zu erreichen.<br/>
          <a href="/karte.gif" target="_blank" rel="nofollow">
            Anfahrtsbeschreibung als Karte
          </a>
        </p>
        <p>
          Insbesondere im Bereich des Wirtschafts-, Straf- und Arbeitsrechts
          sind wir aber selbstverst&auml;ndlich deutschlandweit und
          international t&auml;tig und unterwegs          
        </p>
        <p>
          Mangels barrierefreiem Zugang werden im Bereich Bad Aibling
          gehbehinderten Personen Hausbesuche kostenfrei angeboten.
        </p>
        <p>
          F&uuml;r Beratung und Vertretung im{" "}
            <a href="http://www.alpinrecht.eu" target="_blank" rel="noreferrer">
              Alpinrecht
            </a>{" "}
          gelten g&uuml;nstige Anfahrtspauschalen f&uuml;r den bayerischen
          Alpenbereich.
        </p>
      </div>
    </Layout>
  )
}
